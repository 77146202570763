var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-container',{staticClass:"auth-wrap bg-theme"},[_c('div',{staticClass:"content bg-f w-100"},[_c('div',{staticClass:"cus-header dp-flex justify-btw align-item-c title-color"},[_c('span',[_vm._v("凭证管理")]),_c('el-button',{attrs:{"type":"primary","plain":"","size":"small"},on:{"click":_vm.handleSyncVoucher}},[_vm._v(" 同步凭证 ")])],1),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"height":_vm.tableHeight}},[_c('el-table-column',{attrs:{"label":"账户信息","width":"370"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"dp-flex align-item-c",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.changeCurrentNum(scope.row.id)}}},[_c('div',[_c('img',{staticClass:"mr-10",staticStyle:{"width":"32px","height":"32px","border-radius":"50%"},attrs:{"src":scope.row.thirdUser.thirdUserAvatarUrl}})]),_c('div',{staticStyle:{"line-height":"initial"}},[_c('p',[_c('img',{staticStyle:{"width":"12px","height":"12px","border-radius":"50%"},attrs:{"src":"https://app.sinoclick.com/static/media/fb.d03076a0.svg"}}),_vm._v(" "+_vm._s(scope.row.displayName ? scope.row.displayName : scope.row.thirdUser.thirdUserName)+" "),_c('span',{staticClass:"small-gray"},[_vm._v("("+_vm._s(scope.row.thirdUser.thirdUserEmail)+")")])]),_c('p',{staticClass:"small-gray"},[_vm._v("ID："+_vm._s(scope.row.thirdUser.thirdUserId))])])])]}}])}),_c('el-table-column',{attrs:{"label":"最后授权时间","prop":"update_time"}}),_c('el-table-column',{attrs:{"label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"dp-flex align-item-c"},[_c('span',{class:[
                'small-circle',
                {
                  'bg-success': scope.row.status == 'VALID',
                  'bg-danger': scope.row.status == 'INVALID',
                  'bg-info': scope.row.status == 'EXPIRED',
                },
              ]}),_c('span',{staticStyle:{"margin-left":"8px"}},[_vm._v(_vm._s(scope.row.status == 'VALID' ? '有效' : scope.row.status == 'INVALID' ? '无效' : '过期'))])])]}}])}),_c('el-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-dropdown',{attrs:{"size":"small","placement":"bottom"},on:{"command":(command) => _vm.handleCommand(command, { row: scope.row })}},[_c('el-button',{attrs:{"type":"text"}},[_vm._v(" 操作 "),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l((_vm.authOperates),function(item,index){return _c('el-dropdown-item',{key:index,attrs:{"command":item.id}},[_vm._v(_vm._s(item.name))])}),1)],1)]}}])})],1),_c('div',{staticClass:"pagination"},[_c('el-pagination',{attrs:{"current-page":_vm.page,"page-size":10,"layout":"total, prev, pager, next, jumper","total":_vm.total},on:{"current-change":_vm.handleCurrentChange}})],1)],1),_c('sync-voucher',{attrs:{"show":_vm.showSyncVoucher,"list":_vm.remoteVouchers},on:{"update:show":function($event){_vm.showSyncVoucher=$event},"onsync":_vm.submitSyncVoucher}}),_c('el-dialog',{attrs:{"title":"修改状态","visible":_vm.showChangeStatus,"width":"400px"},on:{"update:visible":function($event){_vm.showChangeStatus=$event}}},[_c('el-form',[_c('el-form-item',{attrs:{"label":"选择状态"}},[_c('el-radio-group',{model:{value:(_vm.voucherStatus),callback:function ($$v) {_vm.voucherStatus=$$v},expression:"voucherStatus"}},[_c('el-radio',{attrs:{"label":"VALID"}},[_vm._v("有效")]),_c('el-radio',{attrs:{"label":"INVALID"}},[_vm._v("无效")])],1)],1)],1),_c('span',{attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.showChangeStatus = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleChangeStatus}},[_vm._v("确 定")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }